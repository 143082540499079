import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { switchMap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Member } from '../member';
import { MemberService } from '../member.service';

@Component({
  selector: 'app-signature',
  templateUrl: './signature.component.html',
  styleUrls: ['./signature.component.scss'],
})
export class SignatureComponent implements OnInit {
  public member$: Observable<Member>;

  constructor(private route: ActivatedRoute, private router: Router, private memberService: MemberService) {}

  ngOnInit(): void {
    this.member$ = this.route.paramMap.pipe(
      switchMap((params: ParamMap) =>
        this.memberService.getMember$(params.get('member')))
    );
  }
}
