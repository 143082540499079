import { Injectable } from '@angular/core';
import { Member } from './member';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class MemberService {

  public get members$(): Observable<Array<Member>> {
    return of(this.members);
  }

  private members: Array<Member> = [
    {
      username: 'jm.alfaya',
      name: 'José Manuel',
      surname: 'Alfaya Montero',
      title: 'Ingeniero Software y Administrador de Fincas',
      pictureFile: 'jm.alfaya.png',
    },
    {
      username: 's.prats',
      name: 'Sílvia',
      surname: 'Prats Ruano',
      title: 'Graduada en Contabilidad y Finanzas',
      pictureFile: 's.prats.png',
    },
    {
      username: 'jm.montero',
      name: 'Juana María',
      surname: 'Montero Rotger',
      title: 'Administradora de Fincas',
      pictureFile: 'avatar.png',
    },
    {
      username: 'e.carmona',
      name: 'Elena',
      surname: 'Carmona Hernández',
      title: 'Técnico Superior en Administración y Finanzas',
      pictureFile: 'avatar.png',
    }
  ];

  constructor() { }

  public getMember$(username: string): Observable<Member> {
    return this.members$.pipe(
      map((members: Array<Member>) => members.find(m => m.username === username))
    );
  }
}
