import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { MemberService } from '../member.service';
import { map } from 'rxjs/operators';
import { Member } from '../member';

@Injectable({
  providedIn: 'root'
})
export class SignatureGuard implements CanActivate {
  constructor(private memberService: MemberService, private router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

      return this.router.parseUrl('/');

    // return this.memberService.getMember$(next.paramMap.get('member')).pipe(
    //   map((member: Member) => member !== undefined ? true : this.router.parseUrl('/'))
    // );
  }
}
