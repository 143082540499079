<mat-toolbar>
  <mat-toolbar-row>
    <span class="spacer"></span>
    <div class="info-cards">
      <a href="tel:871513606">
        <mat-card class="info-button-card">
          <mat-card-header>
            <div mat-card-avatar>
              <mat-icon aria-hidden="false" aria-label="Icono de teléfono">call</mat-icon>
            </div>
            <mat-card-title>TELÉFONO</mat-card-title>
            <mat-card-subtitle>871 513 606</mat-card-subtitle>
          </mat-card-header>
        </mat-card>
      </a>
      <mat-divider [vertical]="true"></mat-divider>
      <a href="mailto:jyjreformas@gmail.com">
        <mat-card class="info-button-card">
          <mat-card-header>
            <div mat-card-avatar>
              <mat-icon aria-hidden="false" aria-label="Icono de e-mail">mail</mat-icon>
            </div>
            <mat-card-title>EMAIL</mat-card-title>
            <mat-card-subtitle>jyjreformas@gmail.com</mat-card-subtitle>
          </mat-card-header>
        </mat-card>
      </a>
      <mat-divider [vertical]="true"></mat-divider>
      <mat-card class="info-button-card">
        <mat-card-header>
          <div mat-card-avatar>
            <mat-icon aria-hidden="false" aria-label="Icono de reloj">schedule</mat-icon>
          </div>
          <mat-card-title>HORARIO DE OFICINA</mat-card-title>
          <mat-card-subtitle>De Lunes a Viernes de 09:30 a 14:30</mat-card-subtitle>
        </mat-card-header>
      </mat-card>
    </div>
  </mat-toolbar-row>
</mat-toolbar>
