<div class="header">
  <img class="logo" src="assets/logo.png">
  <h1>Su empresa profesional, cercana y al mejor precio</h1>
</div>
<div class="centered-section">
  <h1>¿Quienes somos?</h1>
  <div class="piecex2">
    <p>
      Somos una empresa que nació en 1997, pionera en ofrecer a nuestros clientes todos los servicios integrales de cualquier obra, reforma o reparación, con el afán de que el cliente no se tenga que preocupar de ninguna gestión o coordinación entre los distintos industriales (albañiles, fontaneros, electricistas, pintores, etc.)
    </p>
    <mat-divider></mat-divider>
  </div>
  
  
  <div class="flex-centered">
    <div class="piece">
      <mat-icon class="color-red" aria-hidden="false" aria-label="Icono de acreditación">assignment_ind</mat-icon>
      <p>Contamos con todos los carnés y acreditaciones oficiales como constructor, instalador de fontanería, gas, aire acondicionado y calefacción.</p>
    </div>
    <div class="piece">
      <mat-icon class="color-red" aria-hidden="false" aria-label="Icono de herramientas">handyman</mat-icon>
      <p>Para nosotros todos los trabajos y clientes son igual de importantes por lo que realizamos tanto trabajos grandes como reparaciones por pequeñas que sean.</p>
    </div>
  </div>
</div>

<div class="centered-section bg-blue color-white">
  <h1>¿Qué ofrecemos?</h1>
  <div class="piecex2">
    <p>
      Ofrecemos gran calidad, servicio, profesionalidad y garantía, todo ello a buen precio.
    </p>
    <mat-divider></mat-divider>
  </div>
  <div class="flex-centered">
    <div class="piece">
      <mat-icon aria-hidden="false" aria-label="Icono de soporte">support</mat-icon>
      <h2>Disponibilidad</h2>
      <p>Estamos disponibles para usted siempre que nos necesite mediante nuestro <b>Servicio de Urgencias</b>.
        Trabajamos en toda la isla.</p>
    </div>
    <div class="piece">
      <mat-icon aria-hidden="false" aria-label="Icono de ingeniero">engineering</mat-icon>
      <h2>Calidad</h2>
      <p>A parte de tener todas las titulaciones contamos con un arquitecto para la realización de todo tipo de proyectos.</p>
    </div>
    <div class="piece">
      <mat-icon aria-hidden="false" aria-label="Icono de carpeta OK">assignment_turned_in</mat-icon>
      <h2>Diversidad</h2>
      <p>Realizamos todo tipo de trabajos, tanto obras como reparaciones. Así como también ofrecemos Informes de Evaluación de Edificios (IEE), certificados de calificación energética, etc.</p>
    </div>
  </div>
</div>

<div class="centered-section">
  <h1>¿Dónde encontrarnos?</h1>
  <div class="flex">
    <div class="piece">
      <h2>Teléfono</h2>
      <p><a href="tel:871513606">871 513 606</a></p>
      <h2>Urgencias</h2>
      <p><a href="tel:610453550">610 45 35 50</a></p>
    </div>
    <div class="piece">
      <h2>Email</h2>
      <p><a href="mailto:jyjreformas@gmail.com">jyjreformas@gmail.com</a></p>
    </div>
    <div class="piece">
      <h2>Dirección</h2>
      <p>Jaume Balmes 38 <br>
        Palma de Mallorca<br>
        07005<br>
        Islas Baleares<br>
        España
      </p>
    </div>
    <mat-divider [vertical]="true"></mat-divider>
    <div class="piece">
      <h2>Horario de oficina</h2>
      <p>
        De Lunes a Viernes<br>
        de 09:30 a 14:30
      </p>
    </div>
  </div>
</div>
