<div class="header">
</div>
<mat-divider></mat-divider>
<br>
<div style="padding: 20px;">A continuación se muestra la firma del miembro del equipo:</div>
<br>
<div class="signature" *ngIf="(member$ | async) as member">
  <div style="padding: 20px;">
    <span style="display:inline-block; font-size: 1.5em;">{{ member.name }} {{ member.surname }}</span>
    <br>
    <span style="display:inline-block;">{{ member.title }}</span>
    <br>
    <img width="128" src="assets/team/{{ member.pictureFile }}"
      style="border: solid 1px #feb325; border-radius: 100px; margin-top: 12px;">
  </div>
  <div style="padding: 20px; background-color: #f8f8f8;">
    <div style="width: 300px; display: inline-block;">
      <ul style="list-style-type: none; padding-inline-start: 0; margin: 0;">
        <li style="margin: 8px 0;">
          <img width="24" style="vertical-align: middle; margin-right: 12px;"
            src="assets/icons/baseline_mail_black_24dp.png"><span>{{ member.username }}@totalfincas.es</span>
        </li>
        <li style="margin: 8px 0;">
          <img width="24" style="vertical-align: middle; margin-right: 12px;"
            src="assets/icons/baseline_call_black_24dp.png"><span>871 513 606</span>
        </li>
        <li style="margin: 8px 0;">
          <img width="24" style="vertical-align: middle; margin-right: 12px;"
            src="assets/icons/baseline_location_on_black_24dp.png"><span>Jaume Balmes 38 BJ</span>
        </li>
        <li style="margin: 8px 0;">
          <img width="24" style="vertical-align: middle; margin-right: 12px;"
            src="assets/icons/baseline_public_black_24dp.png"><span>www.totalfincas.es</span>
        </li>
        <br>
      </ul>
    </div>
    <div style="display: inline-block; min-width: calc(100% - 600px);"></div>
    <div style="text-align: center; width: 300px; display: inline-block;">
      <img width="300" src="assets/logo-banner_512x512.png" style="margin: 24px 0;">
      <br>
      <span>Horario de oficina:<br>De Lunes a Viernes de 09:30 a 14:30</span>
    </div>
  </div>
  <div style="padding: 20px;">
    <img width="24" style="margin-right: 12px;"
      src="assets/leaf.jpeg">
    <span>Piénselo antes de imprimir este e-mail</span>
    <br><br>
    <p>
      <small>
        De conformidad con lo dispuesto en la normativa vigente sobre protección de datos personales y en el Reglamento
        (UE) 2016/679 de 27 de abril de 2016 (GDPR), sus datos personales y dirección de correo electrónico serán
        tratados bajo la responsabilidad de Juana María Montero Rotger. Finalidad: envío de comunicaciones sobre
        nuestros productos y servicios. Conservación: mientras exista un interés mutuo para ello, en función de los
        plazos legales aplicables. Legitimación: consentimiento del interesado o ejecución de un contrato.
        Destinatarios: colaboradores. Los datos no serán comunicados a terceros, salvo obligación legal. Le informamos
        que puede ejercer los derechos de acceso, rectificación y supresión de sus datos, así como los de limitación,
        portabilidad y oposición a su tratamiento, mediante notificación escrita, a la dirección C/ Jaume Balmes 38,
        bajos, 07005 Palma de Mallorca, Illes Balears o enviando un mensaje al correo electrónico <a href="mailto:info@totalfincas.es">info@totalfincas.es</a>.
        Más información en <a href="https://www.totalfincas.es">www.totalfincas.es</a>. Si considera que el tratamiento no se ajusta a la normativa vigente,
        podrá presentar una reclamación ante la autoridad de control en <a href="https://www.aepd.es">www.aepd.es</a>.
      </small>
    </p>
    <p>
      <small>
        AVISO LEGAL: Este mensaje y sus archivos adjuntos van dirigidos exclusivamente a su destinatario, pudiendo
        contener información confidencial sometida a secreto profesional. No está permitida su comunicación,
        reproducción o distribución sin la autorización expresa de Juana María Montero Rotger. Si usted no es el
        destinatario final, por favor elimínelo e infórmenos por esta vía.
      </small>
    </p>
  </div>
</div>
