import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LandingComponent } from './pages/landing/landing.component';
import { SignatureComponent } from './pages/team/signature/signature.component';
import { SignatureGuard } from './pages/team/signature/signature.guard';


const routes: Routes = [
  { path: '', component: LandingComponent },
  { path: 'team/:member/signature', component: SignatureComponent, canActivate: [SignatureGuard] },
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
